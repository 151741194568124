
import Intro from "./components/Intro.vue";
import Tabs from "./components/Tabs.vue";
import PostAddressesForPlotNumbers from "~/graphql/Property/PostAddressesForPlotNumbers.gql";
import { getPropertyHeaderQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import NoFremiumAccess from "~/components/NoFremiumAccess.vue";

export default {
  name: "PropertyNuxtParent",

  components: {
    Intro,
    Tabs,
    NoFremiumAccess,
  },

  apollo: {
    propertyByBFENumber: getPropertyHeaderQuery,
    addressesForPlotNumbers: {
      query: PostAddressesForPlotNumbers,
      variables() {
        return {
          input: this.propertyByBFENumber.plots?.filter((plot) => plot != null).map((x) => x.plotNumber),
        };
      },
      skip() {
        return !this.propertyByBFENumber || !this.propertyByBFENumber.plots;
      },
    },
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.propertyByBFENumber.loading || this.$apollo.queries.addressesForPlotNumbers.loading;
    },

    propertyType: function () {
      if (this.propertyByBFENumber.condo != null) {
        return "condo";
      } else if (this.propertyByBFENumber.isDividedInCondos) {
        return "propertyDividedInCondos";
      } else if (this.propertyByBFENumber.buildingOnForeignPlot) {
        return "buildingOnForeignPlot";
      } else {
        return "mainProperty";
      }
    },

    propertyHeader: function () {
      return {
        icon: "building",
        text: this.$options.filters.addressLong(getPropertyAddress(this.propertyByBFENumber)) ?? this.$t("NO_ACCESS_ADDRESS"),
      };
    },

    propertyFigures: function () {
      let propertyInfo = {};
      let figures = [];

      figures.push({
        title: this.$t("OVERVIEW_BFE"),
        color: "--color-purple-900",
        body: [{ text: this.propertyByBFENumber.bfeNumber, copyType: "number" }],
      });

      if (this.propertyType === "mainProperty" || this.propertyType == "propertyDividedInCondos") {
        figures.push({
          title: this.$t("OVERVIEW_TITLE"),
          color: "--color-purple-900",
          body: this.cadastralInformation,
          tableNavigationTabTargetId: 0,
        });
        if (this.propertyAccessAddresses && this.propertyAccessAddresses.length > 0) {
          figures.push({
            title: this.$t("PROPERTY_POSTAL_ADDRESS"),
            color: "--color-purple-900",
            body: this.propertyAccessAddresses,
            tableNavigationTabTargetId: 5,
          });
        }
      }

      if (this.propertyType === "condo") {
        figures.push({
          title: this.$t("CONDO_PART_OF"),
          color: "--color-purple-900",
          body: this.condoMainPropertyInformation,
        });
      }

      if (this.propertyType === "buildingOnForeignPlot") {
        figures.push({
          title: this.$t("CONDO_PART_OF"),
          color: "--color-purple-900",
          body: [
            {
              text: this.propertyByBFENumber.buildingOnForeignPlot.totalRealEstateId,
              copyType: "number",
              link: {
                to: "explore-property-id",
                params: {
                  id: this.propertyByBFENumber.buildingOnForeignPlot.totalRealEstateId,
                },
              },
            },
          ],
        });
      }

      propertyInfo.type = this.propertyType;
      propertyInfo.figures = figures;
      propertyInfo.owners = this.ownerInformation;
      propertyInfo.administrators = this.administratorInformation;

      return propertyInfo;
    },

    propertyAccessAddresses: function () {
      if ((this.propertyType === "mainProperty" || this.propertyType == "propertyDividedInCondos") && this.addressesForPlotNumber) {
        return this.addressesForPlotNumbers.map((y) => {
          return {
            text: this.$options.filters.addressShort(y),
            copyType: "body",
          };
        });
      }
      return [];
    },

    cadastralInformation: function () {
      if (this.propertyType === "mainProperty" || this.propertyType === "propertyDividedInCondos") {
        let plots = this.propertyByBFENumber.plots;
        let plotsOut =
          plots
            ?.filter((plot) => plot != null)
            ?.flatMap((plot) => {
              return {
                text: `${plot.ownersGuildName}: ${plot.matrikelNumber}`,
                copyType: "body",
              };
            }) ?? [];
        return plotsOut;
      } else {
        return [];
      }
    },

    ownerInformation: function () {
      let owners = this.propertyType === "condo" ? this.propertyByBFENumber.condo.owners : this.propertyByBFENumber.owners;
      return owners?.filter((owner) => owner && owner.name != "");
    },

    administratorInformation: function () {
      return this.propertyByBFENumber.administrators?.filter((administrator) => administrator.name != "" || administrator.cvrNumber);
    },

    condoMainPropertyInformation: function () {
      let mainProperty = this.propertyByBFENumber.condo.property;
      let address = mainProperty?.plots?.find((plot) => plot != null && plot.address != null).address;
      if (address != null) {
        let addressString = `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.city}`;
        return [
          {
            text: addressString,
            copyType: "body",
            link: {
              to: "explore-property-id",
              params: {
                id: mainProperty.bfeNumber,
              },
            },
          },
        ];
      }
      return "";
    },

    menuTabs: function () {
      let tabs = [
        {
          title: this.$t("COMPANY_TAB_OVERVIEW"),
          to: "overview",
          supportedBy: ["mainProperty", "propertyDividedInCondos"],
        },
        {
          title: this.$t("MENU_BUILDINGS"),
          to: "buildings",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo", "buildingOnForeignPlot"],
        },
        {
          title: this.$t("MENU_OWNERS"),
          to: "owners",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo", "buildingOnForeignPlot"],
        },
        {
          title: this.$t("TENANT_TAB"),
          to: "tenants",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo"],
        },
        {
          title: this.$t("MENU_COMMERCIAL"),
          to: "commercial",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo"],
        },
        {
          title: this.$t("MENU_EASEMENTS"),
          to: "easements",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo"],
        },
        {
          title: this.$t("MENU_TRANSACTIONS"),
          to: "transactions",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo", "buildingOnForeignPlot"],
        },
        {
          title: this.$t("MENU_MORTGAGES"),
          to: "mortgages",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo"],
        },
        {
          title: this.$t("MENU_CONSTRUCTION_CASES"),
          to: "constructioncases",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo"],
        },
        {
          title: this.$t("MENU_DOCUMENTS"),
          to: "documents",
          supportedBy: ["mainProperty", "propertyDividedInCondos", "condo"],
        },
      ];

      if (this.$isFeatureSupported("bofpDocuments")) {
        tabs.find((t) => t.to === "documents").supportedBy.push("buildingOnForeignPlot");
      }

      return tabs.filter((t) => t.supportedBy.includes(this.propertyType));
    },

    hasEasements: function () {
      return this.propertyType != "buildingOnForeignPlot";
    },
  },
};
